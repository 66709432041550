import { rem, Select } from '@mantine/core';
import { GridApi } from 'ag-grid-community';
import useFilterValue, { FilterChangeCallback } from 'components/tables/filters/hooks/use-filter-value';
import { RevisionRow } from 'components/tables/data/revision/types';
import { useEffect, useMemo, useState } from 'react';

type RevisionTechnician = RevisionRow['technician'];

/**
 * The key of the filter. Must be equal to the column field.
 */
export const FILTER_KEY = 'technician.userId' as const;

/**
 * Params passed to the TechnicianSelectFilter component
 */
export interface TechnicianSelectFilterProps {
  api: GridApi<RevisionRow>;
  onChange?: FilterChangeCallback;
}

/**
 * Filter component for the technician column.
 *
 * This filter assumes:
 *   - column field is `technician.userId`
 */
export default function TechnicianSelectFilter({ api, onChange }: TechnicianSelectFilterProps) {
  const [value, setValue] = useFilterValue({ api, filterKey: FILTER_KEY, onChange });
  const [technicians, setTechnicians] = useState<RevisionTechnician[]>([]);

  const options = useMemo(
    () =>
      technicians
        .map(({ userId, fullName }) => ({ value: String(userId), label: fullName }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [technicians]
  );

  useEffect(() => {
    /** Finds unique templates and sets them as options. */
    const findUniqueTechnicians = () => {
      const uniqueTechnicians = new Map<number, RevisionTechnician>();

      api.forEachNode(({ displayed, data: { technician } = {} }) => {
        if (displayed && technician && !uniqueTechnicians.has(technician.userId)) {
          uniqueTechnicians.set(technician.userId, technician);
        }
      });

      setTechnicians(Array.from(uniqueTechnicians.values()));
    };

    api.addEventListener('modelUpdated', findUniqueTechnicians);
    return () => api.removeEventListener('modelUpdated', findUniqueTechnicians);
  }, []);

  return (
    <Select
      data={options}
      clearable
      w="100%"
      size="sm"
      placeholder="Všetci technici"
      value={String(value)}
      onChange={setValue}
      styles={{ dropdown: { minWidth: rem(220) } }}
    />
  );
}
